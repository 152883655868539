import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { css, cx } from 'emotion'

import Page from 'layouts/Page'
import Social from 'components/Social'
import { pinkRed, sepia, spaceCadet, themeBlue } from 'constants/colors'

const about = css`
    @media only screen and (max-width: 900px) {
        width: 100%;
    }
    width: 80%;
`

const About = () => {
    return (
        <Page title="About">
            <h1>About</h1>
            <section className={about}>
                <p>
                    I'm Daniel Martinez, a data enthusiast software engineer
                    based in Madrid, Spain. I focus on creating digital products
                    with great user experience and high performance.
                    <br />
                    <br />
                    I'm passionate about the web, software engineering,
                    artificial intelligence and everything in between.
                    <br />
                    <br />I deeply care about education and empowering
                    individuals through free and accessible information.
                </p>
            </section>
            <Social />
        </Page>
    )
}

export default About

import React from 'react'
import { css } from 'emotion'
import { FaDev, FaGithub, FaTwitter, FaLinkedin } from 'react-icons/fa'

const social = css`
    display: flex;
    a {
        font-size: 28px;
        margin: 0 0.5rem;
    }
`

function Social() {
    return (
        <div className={social}>
            <a href="https://dev.to/nopestack" target="blank">
                <FaDev />
            </a>
            <a href="https://github.com/nopestack" target="blank">
                <FaGithub />
            </a>
            <a href="https://twitter.com/nopestack" target="blank">
                <FaTwitter />
            </a>
            <a href="https://linkedin.com/in/ldmartinezg" target="blank">
                <FaLinkedin />
            </a>
        </div>
    )
}

export default Social
